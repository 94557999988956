<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <h1>Facebook Data Assistant</h1>
        <p>
          Lattehub's Facebook Reporting Assistant extension shows you real-time data of main tracking events (Content
          views, Adds to cart, Checkouts initiated, Purchases), Purchase ROAS (return on advertising spend) in your
          Facebook Ads.
        </p>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" md="4">
        <h3>Facebook Pixel</h3>
        <p>
          Facebook Pixel helps you create ad campaigns to find new customers that look most like your buyers.
        </p>
      </v-col>
      <v-col cols="12" md="8">
        <h4 class="mb-5">Facebook Pixel IDs & Conversions API</h4>
        <v-row v-for="(item, ind) in facebookPixel" :key="ind">
          <v-col md="5" cols="5" class="py-0"><h3>Pixel ID</h3></v-col>
          <v-col md="5" cols="5" class="py-0"><h3>Access Token</h3></v-col>
          <v-col md="5" cols="5" class="py-0 mb-4"
            ><v-text-field hide-details="" v-model="item.id"></v-text-field>
          </v-col>
          <v-col md="5" cols="5" class="py-0 mb-4"
            ><v-text-field hide-details="" v-model="item.token"></v-text-field
          ></v-col>
          <v-col class="pt-1" md="2" cols="2"
            ><v-btn class="delete-btn" color="primary" outlined @click="reduceOption(ind)" v-if="ind > 0"
              ><v-icon>mdi mdi-delete mdi-18px</v-icon></v-btn
            ></v-col
          >
          <v-col
            v-if="item.id == ''"
            md="5"
            cols="5"
            class="pb-0 pt-0 mb-4"
            style="line-height: 12px;word-break: break-word;color: red; font-size: 12px"
            >Please fill in Pixel ID.</v-col
          >
        </v-row>
        <v-row>
          <v-col class="" md="2" sm="2"
            ><v-btn class="pt-0" color="primary" @click="addOption">+ Add more</v-btn></v-col
          >
          <v-spacer></v-spacer>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      listPixel: [
        {
          id: '',
          token: '',
        },
      ],
    };
  },
  props: {
    facebookPixel: {
      type: Array,
    },
  },
  methods: {
    reduceOption(index) {
      this.$emit('removePixel', index);
    },
    addOption() {
      this.$emit('addPixel');
    },
  },
};
</script>
