<template>
  <div class="preferences__page">
    <v-btn @click="$router.go(-1)" small depressed>
      <v-icon left>
        mdi-arrow-left
      </v-icon>
      SALE CHANNELS
    </v-btn>
    <v-row no-gutters>
      <v-col cols="12" v-if="isLoading">
        <SkeletonLoaders type="card-heading, list-item-three-line" />
      </v-col>
      <v-col cols="12" v-else>
        <Criteo v-if="routePath.includes('criteo')" />
        <GoogleAnalytic v-if="routePath.includes('google')" />
        <GoogleAdsTracking
          :data="{
            googleAdsConversionTrack: attributes.googleAdsConversionTrack,
            googleAdsConversionLabel: attributes.googleAdsConversionLabel,
          }"
          ref="google-ads-tracking"
          v-if="routePath.includes('ggads')"
        />
        <FacebookPixel
          @removePixel="removePixel"
          @addPixel="addPixel"
          :facebookPixel="facebookPixel"
          v-if="routePath.includes('facebook')"
        />
        <TiktokIdPixel
          @removeTikTokPixel="removeTikTokPixel"
          @addTikTokPixel="addTikTokPixel"
          :tiktok="tiktok"
          v-if="routePath.includes('tiktok')"
        />
        <Pinterest v-if="routePath.includes('pinterest')" />
        <Bing v-if="routePath.includes('bing')" />
        <Klaviyo v-if="routePath.includes('klaviyo')" />
        <TrustPilot v-if="routePath.includes('trustPilot')" />
      </v-col>
      <v-col cols="12" class="text-end">
        <v-btn @click="submit" :disabled="checkEmptyId()" :loading="isLoadingAction" color="primary">Save</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Criteo from './components/CriteoOneTag';
import GoogleAnalytic from './components/GoogleAnalytic';
import GoogleAdsTracking from './components/GoogleAdsTracking';
import FacebookPixel from './components/FacebookPixel';
import TrustPilot from './components/TrustPilot';

import TiktokIdPixel from './components/TiktokIdPixel';
import Pinterest from './components/Pinterest';
import Bing from './components/Bing';
import Klaviyo from './components/Klaviyo';
import { preferencesApi } from '@/apis/preferences';
import { maxLength } from 'vuelidate/lib/validators';
export default {
  components: {
    Criteo,
    GoogleAnalytic,
    GoogleAdsTracking,
    FacebookPixel,
    TiktokIdPixel,
    Pinterest,
    Bing,
    Klaviyo,
    TrustPilot,
  },
  validations: {
    attributes: {
      favIcon: {},
      homePageTitle: {
        maxLength: maxLength(70),
      },
      homePageDescription: {
        maxLength: maxLength(320),
      },
      googleAnalyticsIds: {},
      googleAdsConversionTrack: {},
      facebookPixel: {},
      criteo: {},
      klaviyoApiKey: {},
      addionalScriptHead: {},
      addionalScriptBody: {},
      passwordProtectedEnable: {},
      passwordProtected: {
        maxLength: maxLength(320),
      },
      personalizePreview: {},
      personalizePreviewOption: {},
      truePilotEmail: '',
    },
  },
  data() {
    const routePath = this.$route.path.toString();
    return {
      loadingData: false,
      routePath: routePath,
      attributes: {
        image: '',
        criteo: {
          id: '',
        },
      },
      klaviyoListAndSegment: [],
      klaviyoListAndSegmentSms: [],
      facebookPixel: [
        {
          id: '',
          token: '',
        },
      ],
      tiktok: [{ pixel_id: '' }],
      mode: '',
      image: {
        src: '',
        position: 1,
      },
      isLoading: false,
      isLoadingAction: false,
    };
  },
  created() {
    this.loadPreferences();
  },
  methods: {
    checkEmptyId() {
      let check;
      this.facebookPixel.forEach(i => {
        if (i.id === '') {
          check = true;
        } else {
          check = false;
        }
      });
      return check;
    },
    removePixel(index) {
      this.facebookPixel.splice(index, 1);
    },
    addPixel() {
      this.facebookPixel.push({
        id: '',
        token: '',
      });
    },
    removeTikTokPixel(index) {
      this.tiktok.splice(index, 1);
    },
    addTikTokPixel() {
      this.tiktok.push({
        pixel_id: '',
      });
    },
    getImageUpLoad(images) {},
    async loadPreferences() {
      this.isLoading = true;
      try {
        this.loadingData = false;
        let data = await preferencesApi.get();
        this.attributes = data.data;
        console.log(data);
        this.loadingData = true;
        this.facebookPixel = this.attributes.facebookPixel || [];
        this.tiktok = this.attributes.tiktok || [];
        if (this.attributes.favIcon) {
          this.image.src = this.attributes.favIcon;
        }
        if (this.attributes.personalizePreviewOption) {
          this.mode = this.attributes.personalizePreviewOption;
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    async submit() {
      this.attributes.facebookPixel = this.facebookPixel.map(x => x);
      this.attributes.tiktok = this.tiktok.map(x => x);
      this.attributes.personalizePreviewOption = this.mode;
      let googleAdsTracking = this.$refs['google-ads-tracking'];
      if (googleAdsTracking) {
        let conversion = googleAdsTracking.conversion;
        this.attributes.googleAdsConversionTrack = conversion.map(c => c.googleAdsConversionTrack).join(',');
        this.attributes.googleAdsConversionLabel = conversion.map(c => c.googleAdsConversionLabel).join(',');
      }
      this.isLoadingAction = true;
      try {
        await preferencesApi.update(this.attributes);
        this.isLoadingAction = false;
      } catch (error) {
        this.isLoadingAction = false;
      }
    },
  },
  watch: {
    '$route.path': {
      handler(path) {
        this.routePath = path;
      },
      deep: true,
    },
  },
};
</script>
