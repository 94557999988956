<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h1>TikTok</h1>
      </v-col>
      <v-col cols="12" md="4">
        <h3>TikTok Integration</h3>
        <p>
          TikTok Pixel helps advertisers to measure advertising effectively by tracking user actions, behavior, and
          conversions on their website.
        </p>
      </v-col>
      <v-col cols="12" md="8">
        <!-- <v-combobox :delimiters="[',']" multiple chips v-model="$parent.attributes.tiktok.pixel_id">
        <template v-slot:selection="data">
          <v-chip v-bind="data.attrs" close @click:close="removeId(data.item)">{{ data.item }}</v-chip>
        </template>
      </v-combobox> -->
        <v-row v-for="(item, ind) in tiktok" :key="ind">
          <v-col class="py-0" cols="12" md="12"><h3>TikTok Pixel ID</h3></v-col>
          <v-col class="py-0" cols="10" md="10"><v-text-field v-model="item.pixel_id"></v-text-field></v-col>
          <v-col class="pt-1" md="2" cols="2"
            ><v-btn class="delete-btn" color="primary" outlined @click="reduceOption(ind)" v-if="ind > 0"
              ><v-icon>mdi mdi-delete mdi-18px</v-icon></v-btn
            ></v-col
          >
        </v-row>
        <v-row>
          <v-col class="" md="2" sm="2"
            ><v-btn class="pt-0" color="primary" @click="addOption">+ Add more</v-btn></v-col
          >
          <v-spacer></v-spacer>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  methods: {
    // removeId(item) {
    //   let index = this.$parent.attributes.tiktok.indexOf(item);
    //   this.$parent.attributes.tiktok.splice(index, 1);
    // },
    reduceOption(index) {
      this.$emit('removeTikTokPixel', index);
    },
    addOption() {
      this.$emit('addTikTokPixel');
    },
  },
  props: {
    tiktok: {
      type: Array,
    },
  },
};
</script>
