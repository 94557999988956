<template>
  <v-row>
    <v-col cols="12" md="12">
      <h1>Klaviyo</h1>
      <p>
        Klaviyo is an email marketing platform created for online businesses - featuring powerful email and SMS
        marketing automation - to help them acquire, retain and grow their customers.
      </p>
    </v-col>
    <v-col cols="12" md="4">
      <h3>Klaviyo Tracking</h3>
      <p>
        By enabling web tracking on your website, you can collect helpful information around your customers' and orders'
        activities.
      </p>
    </v-col>
    <v-col cols="12" md="8">
      <label>
        Klaviyo Public API Key.
        <a class="rt-link1" href="https://www.klaviyo.com/login?next=/account#api-keys-tab">Get your key</a>
      </label>
      <InputNest
        placeholder="Paste your Klaviyo Public API Key here"
        label=""
        :model="['attributes', 'klaviyoApiKey']"
      />
    </v-col>
    <v-col cols="12" md="4"></v-col>
    <v-col cols="12" md="8" class="pt-0">
      <label for="">Klaviyo Private API Key</label>
      <v-text-field
        hide-details=""
        placeholder="Paste your Klaviyo Private API Key here"
        v-model="$parent.attributes.klaviyoApiKeyPrivate"
        @change="getList"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4"></v-col>
    <v-col v-if="displaySelect" cols="12" md="8">
      <label for="">List & Segments ( Email )</label>
      <v-autocomplete
        hide-details=""
        v-model="klaviyoListAndSegment"
        @change="onSelectListSegment"
        :items="listSegment"
        item-value="list_id"
        item-text="list_name"
      ></v-autocomplete>
    </v-col>
    <!-- <v-col cols="12" md="4"></v-col>
    <v-col v-if="displaySelect" cols="12" md="8">
      <label for="">List & Segments ( SMS )</label>
      <v-autocomplete
        v-model="klaviyoListAndSegmentSms"
        @change="onSelectListSegmentSms"
        :items="listSegment"
        item-value="list_id"
        item-text="list_name"
      ></v-autocomplete>
    </v-col> -->
  </v-row>
</template>
<script>
// import { defineComponent } from '@vue/composition-api'
import STORAGE_NAME from '@/const/storage';
import axios from 'axios';
import { log } from 'handlebars';
export default {
  data() {
    return {
      listSegment: [],
      klaviyoListAndSegment: null,
      klaviyoListAndSegmentSms: null,
      klaviyoPrivateKey: '',
      displaySelect: false,
    };
  },
  mounted() {
    this.klaviyoListAndSegment = this.$parent.attributes.klaviyoListAndSegment;
    this.klaviyoListAndSegmentSms = this.$parent.attributes.klaviyoListAndSegmentSms;
    this.getList();
  },
  methods: {
    onSelectListSegment() {
      console.log(this.klaviyoListAndSegment);
      this.$parent.attributes.klaviyoListAndSegment = this.klaviyoListAndSegment;
    },
    onSelectListSegmentSms() {
      this.$parent.attributes.klaviyoListAndSegmentSms = this.klaviyoListAndSegmentSms;
    },
    async getList() {
      try {
        let storeDomain = localStorage.getItem(STORAGE_NAME.STORE_DOMAIN);
        let res = await axios.get(
          `https://${storeDomain}/api/stores/public/onlinestore/klaviyapiproxy?api_key=` +
            this.$parent.attributes.klaviyoApiKeyPrivate,
          {
            headers: {
              Accept: 'application/json',
            },
          },
        );
        this.displaySelect = true;
        this.listSegment = res.data;
        console.log(res);
        console.log(this.listSegment);
      } catch (error) {
        // console.log(1111111111);
        this.displaySelect = false;
      }
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none !important;
}
a:hover {
  text-decoration: underline !important;
}
</style>
