import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);

function get() {
  return storeRequest.get('/stores/onlinestore/admin/preference');
}

function update(params) {
  return storeRequest.put('/stores/onlinestore/preference', params);
}

export const preferencesApi = {
  get,
  update,
};
