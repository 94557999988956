<template>
  <v-row>
    <v-col cols="12" md="12">
      <h1>Google Ads</h1>
      <p>
        Google Ads is Google's online advertising program. Through Google Ads, you can create online ads to reach people
        exactly when they're interested in the products and services that you offer.
      </p>
      <v-divider class="my-5"></v-divider>
    </v-col>
    <v-col cols="12" md="4">
      <h3>Google Ads Conversions</h3>
      <p>
        Google Ads enables you to track the visitors to your store, and generates reports that will help you with your
        marketing.
      </p>
    </v-col>
    <v-divider class="my-5"></v-divider>
    <v-col cols="12" md="8">
      <v-row v-for="(c, index) in $v.conversion.$each.$iter" :key="index">
        <v-col cols="6">
          <h3>Conversion ID</h3>
          <v-text-field hide-details v-model="c.googleAdsConversionTrack.$model"></v-text-field>
          <div class="red--text" v-if="!c.googleAdsConversionTrack.required && c.googleAdsConversionTrack.$dirty">
            Conversion ID is required.
          </div>
        </v-col>
        <v-col :cols="4">
          <h3>Conversion Label</h3>
          <v-text-field hide-details v-model="c.googleAdsConversionLabel.$model"></v-text-field>
          <div class="red--text" v-if="!c.googleAdsConversionLabel.required && c.googleAdsConversionLabel.$dirty">
            Conversion Label is required.
          </div>
        </v-col>
        <v-col v-if="index > 0" class="pt-1 d-flex align-end" md="2" cols="2"
          ><v-btn class="delete-btn" color="primary" outlined @click="removeConversion(index)"
            ><v-icon>mdi mdi-delete mdi-18px</v-icon></v-btn
          ></v-col
        >
      </v-row>
      <v-btn class="pt-0 mt-3" color="primary" @click="addOption">+ Add more</v-btn>
    </v-col>
  </v-row>
</template>
<script>
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      conversion: [
        {
          googleAdsConversionTrack: '',
          googleAdsConversionLabel: '',
        },
      ],
    };
  },
  validations: {
    conversion: {
      required,
      $each: {
        googleAdsConversionTrack: {
          required,
        },
        googleAdsConversionLabel: {
          required,
        },
      },
    },
  },
  mounted() {
    if (this.data.googleAdsConversionTrack) {
      this.conversion = [];
      let googleAdsConversionTrack = this.data.googleAdsConversionTrack.split(',');
      let googleAdsConversionLabel = this.data.googleAdsConversionLabel.split(',');
      for (let index = 0; index < googleAdsConversionTrack.length; index++) {
        const element = googleAdsConversionTrack[index];
        let obj = {
          googleAdsConversionTrack: element,
          googleAdsConversionLabel: googleAdsConversionLabel[index] ? googleAdsConversionLabel[index] : '',
        };
        this.conversion.push(obj);
      }
    }
  },
  methods: {
    addOption() {
      this.conversion = [...this.conversion, { googleAdsConversionTrack: '', googleAdsConversionLabel: '' }];
    },
    removeConversion(index) {
      this.conversion.splice(index, 1);
    },
  },
};
</script>
